$(document).ready(function() {


	
	// SCROLLREVEAL

	window.sr = ScrollReveal();

	function zoom (el) {
		el.classList.remove('is-animating');
		el.classList.add('is-visible');
	}

	sr.reveal('.fullscreen', { afterReveal: zoom });
	sr.reveal('.scale_animation', {duration: 1500, delay: 0, scale: 0.75});
	sr.reveal('.fade_animation', {duration: 1500, delay: 0, opacity: 0});
	sr.reveal('.fade_animation_del1', {duration: 1500, delay: 100, opacity: 0});
	sr.reveal('.fade_animation_del2', {duration: 1500, delay: 200, opacity: 0});
	sr.reveal('.fade_animation_del3', {duration: 1500, delay: 300, opacity: 0});
	sr.reveal('.top_animation', {origin: 'top', distance: '150%', duration: 1500, delay: 0, opacity: 0, scale: 1});
	sr.reveal('.bottom_animation', {origin: 'bottom', distance: '80%', duration: 1500, delay: 0, opacity: 0, scale: 1});
	sr.reveal('.left_animation', {origin: 'left', distance: '100%', duration: 1500, delay: 0, opacity: 0, scale: 1});
	sr.reveal('.right_animation', {origin: 'right', distance: '100%', duration: 1500, delay: 0, opacity: 0, scale: 1});



	// SMOOTH SCROLL
	$('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});



	// MOSTRA CAMPO TIPOLOGIA_LEAD SOLO SE ESISTE PIU' DI 1 TIPOLOGIA

	var first = $('#tipologia_lead').find('option').eq(0).val();

	if (first != '') {
		var n = $('#tipologia_lead option').length;
		if (n>1) {
			$('#tipologia_lead_container').removeClass('d-none');
			$('<option value="">Seleziona...</option>').insertBefore($('#tipologia_lead option:first-child'));
			$('#tipologia_lead option:first-child').attr('selected',true);
			$('#tipologia_lead').addClass('required');
			$('<div class="field_status">').insertAfter('#tipologia_lead');
			$("#tipologia_lead").val($("#tipologia_lead option:first").val());
		} else {
			$('#tipologia_lead_container').addClass('d-none');
		}
	};



	// FORM REPLACE

	$('input[type="submit"]').prop("value", "Invia la richiesta");
	$(".required").parent('div').find('label').append('<span class="ast">*</span>');



	// SMOOTH SCROLL
	$('a[href*="#contacts"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});



	// BACK TO TOP

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};

});